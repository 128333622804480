import { Enum_Componentmenufootermenulink_Target, FooterEntity } from '@/gql/generated/graphql'
import clsx from 'clsx'
import React from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import NewsletterInput from '../core/NewsletterInput'

type Props = {
  menu: FooterEntity
}

export default function Footer(props: Props) {
  return (
    <footer className='bg-footer-color'>
      <div className='container py-[60px] xl:pb-24'>
        <div className='flex flex-wrap gap-y-8 text-center md:text-left lg:gap-0'>
          <div className='w-full px-4 text-sm text-white sm:w-1/2 lg:w-[25%]'>
            <a href='/' className='mb-8 inline-block' aria-label='Logo'>
              <ResponsiveImage className='max-w-[76px]' image={props.menu.attributes?.logo} />
            </a>
            <ul className='mb-9 flex justify-center gap-x-2.5 md:justify-start'>
              {props.menu.attributes?.socialLinks?.map((link, index) => (
                <li key={link?.id}>
                  <a href={link?.href} target='_blank' aria-label={link?.title ?? ''}>
                    <ResponsiveImage image={link?.icon} />
                  </a>
                </li>
              ))}
            </ul>
            <div className='relative mx-auto block w-52 md:mx-0'>
              <label htmlFor='email' className='mb-2.5 block font-roboto text-sm font-normal text-white'>
                Subscribe
              </label>
              <NewsletterInput 
                inputClassNames='block w-full rounded-3xl border border-white bg-transparent py-2 pl-5 pr-10 text-sm placeholder-white/40 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500'
                buttonClassNames='absolute right-[7px] bottom-[7px]'
                isFooterSection={true}
              />
            </div>
          </div>
          {props.menu.attributes?.items?.map((item, index) => (
            <div key={item?.id} className={clsx('w-full px-4 text-sm text-white sm:w-1/2 lg:w-1/5', { 'lg:w-[25%]': index === 0, 'lg:w-[15%]': index === 3 })}>
              <h2 className='mb-5 font-roboto text-sm font-bold text-white'>{item?.title}</h2>
              <ul className='list-none'>
                {item?.links?.map((link, index) => (
                  <li key={link?.id} className='mb-4'>
                    <a href={link?.href} className={`${link?.classNames ?? ''} font-roboto text-sm font-normal text-white hover:text-light-gray`}>
                      {link?.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className='container'>
        <div className='border-t border-[#2E2E2E] py-4 text-center text-sm text-[#E0E0E0] sm:text-left'>
          Copyright © {new Date().getFullYear()} {props.menu.attributes?.copyright}
        </div>
      </div>
    </footer>
  )
}
