import { ComponentMenuMainMenuItem, Maybe } from '@/gql/generated/graphql'
import { clsx } from 'clsx'
import React, { useState } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  item: Maybe<ComponentMenuMainMenuItem>
}

export default function MobileMenuItem({ item }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const hasItems = (input?: any) => {
    if (!input) return false
    if (input.length < 1) return false
    return true
  }
  return (
    <li onClick={() => setIsOpen((prev) => !prev)} className={clsx('menu-item relative py-2 cursor-pointer', { 'has-child': hasItems(item?.children) })}>
      {hasItems(item?.children) ? (
        <a href='#' data-toggle='sub-menu' className='block font-roboto text-lg font-semibold text-white'>
          {item?.title}
        </a>
      ) : (
        <a href={item?.href ?? '/'} className='block font-roboto text-lg font-semibold text-white'>
          {item?.title}
        </a>
      )}
      {hasItems(item?.children) ? (
        <span className='sub-menu-toggle pointer-events-none absolute right-0 top-1.5 block h-8 w-8'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5 text-white'>
            <path
              fillRule='evenodd'
              d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      ) : null}
      {hasItems(item?.children) && isOpen && (
        <div className='sub-menu visible relative w-full overflow-hidden pl-6 opacity-100'>
          <div className='grid grid-cols-2 gap-4 pt-5'>
            <div className='all-menu-wrap col-span-2'>
              <div className='mb-8 grid grid-cols-1 gap-10'>
                {item?.children?.map((child, index) => (
                  <div className='' key={index}>
                    <h4 className='font-roboto text-lg font-bold text-white'>{child?.title}</h4>
                    <ul>
                      {child?.links?.map((link, index) => (
                        <li key={link?.id}>
                          <a href={link?.href} className={`${link?.classNames ?? ''} font-roboto text-lg font-normal leading-10 text-white hover:underline`}>
                            {link?.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <ul className='bottom-menu flex flex-col gap-y-4'>
                {item?.buttons?.map((button, index) => (
                  <li key={button?.id}>
                    <a href={button?.href ?? undefined} className='font-roboto text-base font-semibold text-light-purple hover:underline'>
                      {button?.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='all-article col-span-2 pt-6'>
              <div className='grid h-full grid-cols-1 gap-x-5'>
                {item?.posts?.map((post, index) => (
                  <div key={post?.id} className='flex h-full flex-col justify-between'>
                    <div className='mb-4'>
                      <div className='article-img relative mb-8 pt-[60%]'>
                        <ResponsiveImage
                          className='absolute left-0 top-0 right-0 bottom-0 h-full w-full rounded-[10px] object-cover'
                          image={post?.post?.data?.attributes?.featureImage}
                        />
                      </div>
                      <a href={`/blog/${post?.post?.data?.attributes?.slug}`} className='block font-roboto text-xl font-normal text-white hover:underline'>
                        {post?.title}
                      </a>
                    </div>
                    <ul className='bottom-menu mb-10 flex flex-col gap-y-4'>
                      <li>
                        <a href={post?.linkHref ?? undefined} className='font-roboto text-base font-semibold text-light-purple hover:underline'>
                          {post?.linkLabel}
                        </a>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  )
}
