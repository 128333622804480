import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useCallback, useState } from 'react'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'

const Locales = [
  { name: 'English', code: 'en' }
]



export default function MobileLanguageSwitcher() {
  const router = useRouter()
  const [isOpenLang, setIsOpenLang] = useState<boolean>(false)

  return (
    <Fragment>
      <li onClick={() => setIsOpenLang(true)} className='has-children menu-item-language relative'>
        <a no-href='#' rel='nofollow' className={clsx('mobLan block font-roboto text-[0] font-semibold text-white sm:text-lg')}>
          {router.locale?.toUpperCase()}
        </a>
      </li>
      <Transition.Root
          enter='transition-all duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-all duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          show={isOpenLang}
          as='div'
          className='w-screen min-h-screen fixed top-0 right-0 z-50 bg-black/60 lg:hidden justify-end flex '
        >
          <Transition.Child
            as='nav'
            enter='transition-all duration-300'
            enterFrom='translate-x-8 opacity-0'
            enterTo='translate-x-0 opacity-100'
            leave='transition-all duration-300'
            leaveFrom='translate-x-0 opacity-100'
            leaveTo='translate-x-8 opacity-0'
            className='z-[1000] h-screen flex flex-col justify-between overflow-y-auto text-white bg-black p-5 w-4/5 relative'
          >
            <div className=''>
              <div className='close-nav-menu flex h-10 items-center justify-end w-full mb-5'>
                <svg
                  onClick={() => setIsOpenLang(false)}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='h-6 w-6 cursor-pointer text-white'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12'></path>
                </svg>
              </div>
              <ul className='pl-5 mobileLanguageSwitcher'>
                {Locales.map((locale, index) => (
                  <li key={locale.code} className='menu-item relative py-2 cursor-pointer'>
                    <Link href={router.asPath} locale={locale.code} className='block font-roboto text-lg font-semibold text-white'>
                      {locale.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Transition.Child>
      </Transition.Root>
    </Fragment>
  )
}
