import React, { CSSProperties, FC } from 'react'
import { Maybe, UploadFileEntityResponse } from '@/gql/generated/graphql'
import { NEXT_PUBLIC_IMAGE_ENDPOINT } from '@/env'

type Props = {
  image?: Maybe<UploadFileEntityResponse>
  className?: string
  id?: string
  style?: CSSProperties
  isEager?: boolean
  notOptimized?: boolean
  isFullSize?: boolean
}

const ResponsiveImage: FC<Props> = (props) => {
  if (!props.image || !props.image.data) return null
  const link = (props.image.data.attributes?.hash !== undefined) ? `${NEXT_PUBLIC_IMAGE_ENDPOINT}/${props.image.data.attributes?.hash}${props.image.data.attributes?.ext}` : props.image.data.attributes?.url;
  const formats = props.image.data?.attributes?.formats
  const attr = props.image.data?.attributes
  const calculateHeightAndWidth = () => {
    if (!props.image) return undefined

    if (props.image.data?.attributes?.ext === '.svg') {
      return { height: attr?.height, width: attr?.width }
    }

    if (props.isFullSize) {
      return { height: attr?.height, width: attr?.width }
    }

    return {
      height: Math.round((attr?.height as number) / 3),
      width: Math.round((attr?.width as number) / 3),
    }
  }

  const processed = calculateHeightAndWidth()

  const generateSrcSet = () => {
    if (props.image && props.image.data?.attributes?.ext === '.svg') return undefined
    if (props.notOptimized) return undefined
    const sourceSets: string[] = []
    if (!formats) return undefined
    Object.keys(formats).forEach((res) => {
      const targetImg = formats[res]
      if (res !== 'thumbnail') {
        sourceSets.push(`${NEXT_PUBLIC_IMAGE_ENDPOINT}/${targetImg.hash}${targetImg.ext} ${res}`)
      }
    })

    return sourceSets.join(',')
  }
  return (
    <img
      style={props.style ?? undefined}
      id={props.id ?? undefined}
      sizes='55vw'
      alt={attr?.alternativeText ?? ''}
      height={processed?.height ?? 'auto'}
      width={processed?.width ?? 'auto'}
      className={props.className ?? undefined}
      src={link}
      srcSet={generateSrcSet()}
      loading={props.isEager ? 'eager' : 'lazy'}
    />
  )
}

export default ResponsiveImage
