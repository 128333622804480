import React, { FC } from 'react'
import Blocks, { RenderFn } from 'editorjs-blocks-react-renderer'
import ResponsiveImage from '../core/ResponsiveImage'
import SlugifyString from '@/utils/slugifyString'

type Props = {
  content?: string
  className?: string
}

const CustomImage: RenderFn<{ file: any }> = ({ data, className = '' }) => {
  const imageData = {
    data: {
      attributes: data.file,
    },
  }
  return <ResponsiveImage isFullSize className={className} image={imageData} />
}

const CustomHeading: RenderFn<{ text: string; level: number }> = ({ data, className = '' }) => {
  switch (data.level) {
    case 1:
      return <h1>{data.text}</h1>
    case 2:
      const sectionId = SlugifyString({ str: data.text })
      return <h2 id={sectionId} className='outline-section scroll-mt-24'>{data.text}</h2>
    case 3:
      return <h3>{data.text}</h3>
    case 4:
      return <h4>{data.text}</h4>
    case 5:
      return <h5>{data.text}</h5>
    default:
      return <h6>{data.text}</h6>
  }
}

const RichText: FC<Props> = (props) => {
  const checkValidJson = (str: string)=> {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
  return (
    <div className={`prose max-w-none prose-h2:text-center prose-h3:text-center prose-a:text-[#8264eb] font-roboto text-xl font-normal ${props.className ?? ''}`}>
      {(props.content && props.content.length > 0 && checkValidJson(props.content)) && 
        <Blocks data={JSON.parse(props.content ?? '{}')} renderers={{ image: CustomImage, header: CustomHeading }} />
      }
    </div>
  )
}

export default RichText