import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import clsx from 'clsx'

const Locales = [
  { name: 'English', code: 'en' }
]

export default function LanguageSwitcher() {
  const router = useRouter()
  // const [isOpenLang, setIsOpenLang] = useState<boolean>(false)
  // const toggleOpenLang = () => setIsOpenLang(value => !value);
  // // const toggleOpenLang = useCallback(() => {
  // //   setIsOpenLang(value => !value);
  // // }, [isOpenLang])

  return (
    <li className='has-children menu-item-language static'>
      <a no-href='#' className={clsx('active block py-5 font-roboto text-lg font-semibold text-white')}>
        {router.locale?.toUpperCase()}
      </a>
      <ul className='sub-menu invisible absolute mt-5 flex w-[310px] flex-wrap gap-y-4 rounded-lg bg-white p-7 opacity-0 shadow-[0_20px_40px_0px_rgba(0,0,0,0.3)] transition-all duration-200'>
        {Locales.map((locale, index) => (
          <li key={locale.code} className='w-2/4'>
            <Link href={router.asPath} locale={locale.code} className='font-roboto text-base font-normal text-black no-underline hover:underline'>
              {locale.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  )
}
