import { PromoEntity } from '@/gql/generated/graphql'
import React, { Fragment, useEffect, useRef, useState } from 'react'
// import cookie from 'js-cookie'
import { Dialog, Transition } from '@headlessui/react'
import RichText from '../renderer/RichText'

type Props = {
  promo: PromoEntity
}

export default function PromoBanner({ promo }: Props) {
  const [showing, setShowing] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const cancelButtonRef = useRef(null)

  function closeCookie() {
    // cookie.set('promo-closed', 'true')
    setShowing(false)

    const introHero = document.getElementById('intro-hero');
    if (introHero) {
      introHero?.classList.remove('have-top-sticky')
    }
  }

  useEffect(() => {
    if(showing) return
    const introHero = document.getElementById('intro-hero');
    const generalHero = document.getElementById('general-hero');
    const mainHeader = document.getElementsByClassName('sticky-outer-wrapper')[0];

    setTimeout(()=> {
      if (introHero) {
        introHero.style.paddingTop = `${mainHeader?.clientHeight + 40}px`;
      }
      if (generalHero) {
        generalHero.style.paddingTop = `${mainHeader?.clientHeight + 40}px`;
      }
    }, 500)
  }, [showing])
  // useEffect(() => {
  //   const hasCookie = cookie.get('promo-closed')
  //   if (!hasCookie) {
  //     setShowing(true)
  //   }

  //   return () => {}
  // }, [])

  return (
    <Fragment>
      <Transition
        show={showing}
        enter='transition-all duration-300'
        enterFrom='-translate-y-8 opacity-0'
        enterTo='-translate-y-0 opacity-100'
        leave='transition-all duration-300'
        leaveFrom='-translate-y-0 opacity-100'
        leaveTo='-translate-y-8 opacity-0'
      >
        <div className='top-sticky relative px-6'>
          <div className='w-full px-[30px] text-center py-[14px] flex flex-wrap gap-x-10 gap-y-5 justify-center items-center relative'>
            <RichText className='promo-content text-center text-white text-lg' content={promo.attributes?.content ?? ''} />
            {promo.attributes?.button &&
              promo.attributes?.show_modal_on_button_click ? (
              <a
                className='cursor-pointer font-Inter inline-block rounded-full bg-[#E91BA7] px-6 py-[6px] text-center text-base font-bold text-white duration-300 ease-in hover:opacity-90 2xl:mt-0'
                onClick={() => setOpenModal(true)}
                
                rel='noreferrer'
              >
                {promo.attributes?.button?.label}
              </a>
            ) : (
              <a
                className='cursor-pointer inline-block rounded-full bg-white px-6 py-[6px] text-center text-base font-bold text-[#8264EB] duration-300 ease-in hover:opacity-90 2xl:mt-0'
                href={promo.attributes?.button?.href ?? undefined}
                
                rel='noreferrer'
              >
                {promo.attributes?.button?.label}
              </a>
            )
            }
          </div>
          <div onClick={closeCookie} className='promo-close absolute right-4 top-4 cursor-pointer xl:top-2/4 xl:-translate-y-1/2'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='h-6 w-6 text-white'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12'></path>
            </svg>
          </div>
        </div>
      </Transition>
      {promo.attributes?.show_modal_on_button_click &&
        <Transition.Root show={openModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[680px]">
                    <div className="bg-white promo-modal-wrap">
                      <RichText className='promo-modal-content' content={promo.attributes?.modal_content ?? ''} />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }
    </Fragment>
  )
}
