import { BlogMenuEntity, MainMenuEntity } from '@/gql/generated/graphql'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import MobileMenuItem from './MobileMenuItem'
import MobileLanguageSwitcher from './MobileLanguageSwitcher'
import LanguageSwitcher from './LanguageSwitcher'
import ProductSignInWidget from './ProductSignInWidget'
import { NEXT_PUBLIC_CDN_URL } from '@/env'

type Props = {
  menu: MainMenuEntity | BlogMenuEntity
  isWhiteTheme?: boolean
  hideLocales?: boolean
}

export default function MobileMenu(props: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSignIn, setIsOpenSignIn] = useState(false)
  const [isOpenTryNow, setIsOpenTryNow] = useState(false)
  return (
    <Fragment>
      <div className='mobile-menu-wrap block w-full py-3 lg:hidden'>
        <div className='container flex items-center justify-between'>
          <a href='/' className='logo-box' aria-label='WiTopia' title='WiTopia'>
            <ResponsiveImage image={props.menu.attributes?.logo} />
          </a>
          <div className='inline-flex items-center'>
            <ul className='flex flex-wrap items-center justify-center space-x-5 text-base'>
              {!props.hideLocales && <MobileLanguageSwitcher />}
              <ProductSignInWidget />
              <li className="has-children menu-item-signup relative hidden">
                <a no-href="#" className="mob-signup font-Roboto hidden rounded-[30px] border-2 border-white py-2 px-6 font-roboto text-lg font-semibold text-white sm:block">Sign up for free</a>
                <ul className='mob-signup-menu invisible absolute -right-2 mt-3 flex w-64 flex-wrap gap-y-6 rounded-lg bg-white p-4 opacity-0 shadow-[0_20px_40px_0px_rgba(0,0,0,0.3)] transition-all duration-200'>
                  <li className='w-full'>
                    <a no-href='#' className='block rounded-lg py-3 px-3 hover:bg-[#F4F8FB]'>
                      <img src={`${NEXT_PUBLIC_CDN_URL}/images/logo-vpn.svg`} className='h-auto max-w-[160px]' alt='vpn' />
                      <span className='mt-3 block font-roboto text-xs font-normal text-[#404042]'>30-Day Unconditional Money-Back Guarantee.</span>
                    </a>
                  </li>
                  <li className='w-full'>
                    <a no-href='#' className='block rounded-lg py-3 px-3 hover:bg-[#F4F8FB]'>
                      <img src={`${NEXT_PUBLIC_CDN_URL}/images/logo-sme.svg`} className='h-auto max-w-[110px]' alt='sme' />
                      <span className='mt-3 block font-roboto text-xs font-normal text-[#404042]'>No credit card, webinar, or sales call required.</span>
                    </a>
                  </li>
                </ul>                
              </li>
            </ul>
            <div onClick={() => setIsOpen(true)} className='open-nav-btn ml-4 cursor-pointer'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className={clsx('h-6 w-6 text-white')}
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25'></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root
        enter='transition-all duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-all duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        show={isOpen}
        as='div'
        className='w-screen min-h-screen fixed top-0 right-0 z-50 bg-black/60 lg:hidden justify-end flex '
      >
        <Transition.Child
          as='nav'
          enter='transition-all duration-300'
          enterFrom='translate-x-8 opacity-0'
          enterTo='translate-x-0 opacity-100'
          leave='transition-all duration-300'
          leaveFrom='translate-x-0 opacity-100'
          leaveTo='translate-x-8 opacity-0'
          className='z-[1000] h-screen flex flex-col justify-between overflow-y-auto text-white bg-black p-5 w-4/5 relative'
        >
          <div className=''>
            <div className='close-nav-menu flex h-10 items-center justify-end w-full mb-5'>
              <svg
                onClick={() => setIsOpen(false)}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-6 w-6 cursor-pointer text-white'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12'></path>
              </svg>
            </div>
            <ul className='pl-5'>
              {props.menu.attributes?.items?.map((item, index) => (
                <MobileMenuItem item={item} key={item?.id} />
              ))}
            </ul>
          </div>
          <div className='button-wrap px-5 flex flex-col text-center'>
            <ul className='mobile-sign-try'>
              <li onClick={() => setIsOpenSignIn((prev) => !prev)} className='parent'>
                <a className='toogle' no-href='#' rel='nofollow'>Sign in</a>
                  {isOpenSignIn && 
                    <ul className='child'>
                      <li><a href='https://my.personalvpn.com/users/sign_in'>personalVPN</a></li>
                      <li><a href='https://www.securemyemail.com/users/sign_in'>SecureMyEmail</a></li>
                    </ul>
                  }
              </li>          
            </ul>
          </div>
        </Transition.Child>
      </Transition.Root>
    </Fragment>
  )
}
