import React, { useEffect, useState } from 'react'
import { NEXT_PUBLIC_CDN_URL, NEXT_PUBLIC_NEWSLETTER_API_URI } from '@/env'
import clsx from 'clsx'
import useDebounce from '@/hooks/useDebounce'

type Props = {
  inputClassNames?: string
  buttonClassNames?: string
  isFooterSection?: boolean
}

const NewsletterInput = (props: Props) => {
  const [subEmail, setSubEmail] = useState('')
  const debouncedKeyword = useDebounce(subEmail, 1000)
  const [isError, setIsError] = useState(false)
  const [subNotification, setSubNotification] = useState('')
  const [showSubNotification, setShowSubNotification] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  
  const handleSubmit = async () => {
    if (debouncedKeyword === '') {
      return
    }
    setIsError(false)
    setShowSubNotification(false)
    setSubNotification('')
    setInProgress(true)
    const API_ENDPOINT = `${NEXT_PUBLIC_NEWSLETTER_API_URI}/strapi-newsletter/newsletter/subscribe`
    const data = new FormData();
    data.append("email", subEmail);
    const res = await fetch(API_ENDPOINT, {
      method: "POST",
      body: data
    });

    setShowSubNotification(true)
    const response = await res.json();
    setInProgress(false)

    if (response.error){
      setIsError(true)
      setSubNotification('Email invalid or already taken!')
      resetStates(5000)
      return
    }
    setSubEmail('')
    setSubNotification('You have been successfully subscribed!')
    resetStates(3000)
  };

  const resetStates = (interval: number) => {
    setTimeout(() => {
      setIsError(false)
      setShowSubNotification(false)
      setSubNotification('')
      setSubEmail('')
    }, interval);
  }


  useEffect(() => {
    return () => { }
  }, [debouncedKeyword])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className='inputWrap'>
      <div className='inputbox relative'>
        <input
          type='email'
          name='email'
          value={subEmail}
          className={clsx(`${props.inputClassNames}`)}
          placeholder='Enter your email'
          onKeyDown={(e) => handleKeyPress(e)}  
          onChange={(e) => setSubEmail(e.target.value)}  
        />
        <button className='absolute right-[7px] top-[7px] subscribe-btn' onClick={handleSubmit}>
          {props.isFooterSection? ( 
              <img src={`${NEXT_PUBLIC_CDN_URL}/images/ic-arrow-subscribe.svg`} alt='subscribe' width='24px' height='24px' />
            ): (
              `Subscribe`
            )
          }
        </button>  
        {showSubNotification ? <div className={clsx( 'mt-2 text-green-400', { 'text-[#DB5A5F]': isError, 'text-[16px]': !props.isFooterSection})}>{subNotification}</div> : ''}
        {inProgress ? <div className={clsx( 'inProgress absolute top-3 left-5 font-roboto font-normal text-sm', { 'w-[75%]': props.isFooterSection, 'bg-footer-color w-[54%] z-10': !props.isFooterSection})}>Please Wait...</div> : ''}            
      </div>
    </div>
  )
}
export default NewsletterInput