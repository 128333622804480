import clsx from 'clsx'
import React from 'react'
import { NEXT_PUBLIC_CDN_URL } from '@/env'

export default function ProductSignInWidget() {

  return (
    <li className={clsx('has-children menu-item-signin static hidden lg:block')}>
      <a no-href='#' rel='nofollow' className='active block py-5 font-roboto text-base font-semibold text-white'>
        Sign in
      </a>
      <ul className={clsx('sub-menu invisible absolute mt-5 flex w-[250px] flex-wrap gap-y-6 rounded-lg bg-white p-4 opacity-0 shadow-[0_20px_40px_0px_rgba(0,0,0,0.3)] transition-all duration-200 md:right-3')}>
        <li className='w-full'>
          <a href='https://my.personalvpn.com/users/sign_in' className='block rounded-lg py-3 px-3 hover:bg-[#F4F8FB]'>
            <img src={`${NEXT_PUBLIC_CDN_URL}/images/logo-vpn.svg`} width='255' height='55' className='h-auto max-w-[160px]' alt='personalVPN' title='personalVPN' />
          </a>
        </li>
        <li className='w-full'>
          <a href='https://www.securemyemail.com/users/sign_in' className='block rounded-lg py-3 px-3 hover:bg-[#F4F8FB]'>
            <img src={`${NEXT_PUBLIC_CDN_URL}/images/logo-sme.svg`} width='153' height='57' className='h-auto max-w-[110px]' alt='SecureMyEmail' title='SecureMyEmail' />
          </a>
        </li>
      </ul>
    </li>
  )
}
